import React from "react";
import clsx from "clsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ContentBlock = ({
  background_color,
  image_position,
  image,
  title,
  title_color,
  title_part_two,
  title_part_two_color,
  content
}) => {
  const left = image_position === 'Left';
  return (
    <section style={{backgroundColor: background_color}}
      className="py-24">
      <div className="xl:container mx-auto px-4">
        <div className="grid lg:grid-cols-2 gap-x-32 gap-y-12 w-full items-center">
          <div className={clsx('flex', left ? 'order-2 lg:order-1' : 'order-2')}>
            <GatsbyImage image={getImage(image)}
              alt={image.alt || ''}
              className="max-w-[300px] lg:max-w-[450px] mx-auto" />
          </div>
          <div className={clsx(left ? 'order-1 lg:order-2' : 'order-1')}>
            <h3 className="font-black text-4xl md:text-5xl mb-4"
              style={{color: title_color}}>{title} {title_part_two && <span style={{color: title_part_two_color}}>{title_part_two}</span>}
            </h3>
            <div className="text-xl inline-block">
              {content.map((i,k) => (
                <span key={k} style={{color: i.color}}>{i.paragraph.text} </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentBlock;