import clsx from "clsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Button from '../../components/Button';
import {getButtonColor} from '../../utils/getButtonColor';

const ImageGridBlock = ({
  background_color,
  title,
  heading_color,
  subheading,
  subheading_color,
  mobile_grid,
  desktop_grid,
  heading_image,
  column_alignment = 'Start',
  cta_button_style,
  cta_button_text,
  cta_button_link = {
    url: '/contact'
  },
  images
}) => {
  return (
    <section className="py-24"
      style={{backgroundColor: background_color}}>
      <div className="xl:container mx-auto px-4 text-center">
        {heading_image && <GatsbyImage image={getImage(heading_image)}
          alt={heading_image.alt || ''} />}
        {title && <h3 className="font-black text-3xl lg:text-4xl mb-2"
          style={{color: heading_color}}>{title.text}</h3>
        }
        {subheading && <p className="font-black text-2xl lg:3xl mb-2"
          style={{color: subheading_color}}>{subheading.text}</p>}
        
        <div className={clsx('lg:max-w-[950px] mx-auto grid gap-x-12 gap-y-12 mb-12 mt-16', `grid-cols-${mobile_grid} lg:grid-cols-${desktop_grid}`, {
          'items-start': column_alignment === 'Start',
          'items-end': column_alignment === 'End'
        })}>
          {images.map((i,k) => (
            <div className="px-4" key={k}>
              <GatsbyImage image={getImage(i.image.gatsbyImageData)}
                alt={i.image.alt || ''}
                className="mb-4" />
              {i.title &&
                <p className="font-black text-xl mb-2">{i.title.text}</p>
              }
              {i.paragraph_one && 
                <div style={{color: i.paragraph_one_color}}
                  dangerouslySetInnerHTML={{__html: i.paragraph_one.html}}></div>
              }
              {i.paragraph_two && 
                <div style={{color: i.paragraph_two_color}}
                  className="mt-2"
                  dangerouslySetInnerHTML={{__html: i.paragraph_two.html}}></div>
              }
            </div>
          ))}
        </div>
        {cta_button_text && <Button type={cta_button_link.link_type === 'Web' ? 'external' : 'link'}
          to={cta_button_link.url}
          className={clsx('transition-colors font-black text-2xl', getButtonColor(cta_button_style))}
          label={cta_button_text}
          padding="px-10 py-2" />}
      </div>
    </section>
  )
}

export default ImageGridBlock;