export const getButtonColor = (value) => {
  switch (value) {
    case 'Teal':
      return 'bg-sourci-teal hover:bg-sourci-teal-100 text-white';
    case 'Pink':
      return 'bg-sourci-pink hover:bg-sourci-pink-100 text-white';
    case 'Black':
      return 'bg-black text-white';
    case 'White':
      return 'bg-white text-sourci-pink';
    default:
      return 'bg-sourci-teal hover:bg-sourci-teal-100 text-white';
  }
}