import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from './Button';
import clsx from 'clsx';

const getButtonColor = (value) => {
  switch (value) {
    case 'Teal':
      return 'bg-sourci-teal hover:bg-sourci-teal-100 text-white';
    case 'Pink':
      return 'bg-sourci-pink hover:bg-sourci-pink-100 text-white';
    case 'Black':
      return 'bg-black text-white';
    case 'White':
      return 'bg-white text-sourci-pink';
    default:
      return 'bg-sourci-teal hover:bg-sourci-teal-100 text-white';
  }
}

const CaseStudyBanner = ({data}) => {
  const {
    buttonColor = 'Teal'
  } = data;
  return (
    <section>
      <div className="relative overflow-hidden w-full h-full py-24">
        <div className="absolute block w-full top-0 left-0 h-full">
          <GatsbyImage image={getImage(data.desktopImage)}
            alt={data.desktopImage.alt || ''}
            placeholder="blurred"
            className="block w-full h-full" />
        </div>
        <div className="relative xl:container flex flex-wrap mx-auto w-full h-full items-center px-6">
          <div className="w-full md:w-4/5">
            {data.titleParts.map((p,k) => (
              <p className="text-4xl md:text-5xl font-black mb-8"
                key={p.id}>
                {p.items.map((i,k) => (
                  <React.Fragment key={k}>
                    <span style={{color: i.color}}>{i.title} </span>
                    {i.new_line && <br />}
                  </React.Fragment>
                ))}
              </p>
            ))}
          </div>
          <div className="md:text-right w-full">
            <Button label={data.button}
              type="link"
              to="/projects/vush"
              className={clsx('transition-colors font-black text-base md:text-lg', getButtonColor(buttonColor))}
              padding="px-10 py-2" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyBanner;