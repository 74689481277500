import clsx from "clsx";
import React from "react";
import CaseStudyBanner from "../components/CaseStudyBanner";
import ContentBlock from "../components/ContentBlock";
import Cta from "../components/Cta";
import ImageBanner from "../components/ImageBanner";
import ProductCategories from "../components/ProductCategories";
import { Seo } from "../components/Seo";
import Button from "../components/Button";
import ImageGridBlock from "../components/Landing/ImageGrid";

const getButtonColor = (value) => {
  switch (value) {
    case 'Teal':
      return 'bg-sourci-teal hover:bg-sourci-teal-100 text-white';
    case 'Pink':
      return 'bg-sourci-pink hover:bg-sourci-pink-100 text-white';
    case 'Black':
      return 'bg-black text-white';
    case 'White':
      return 'bg-white text-sourci-pink';
    default:
      return 'bg-sourci-teal hover:bg-sourci-teal-100 text-white';
  }
}

const SectionBlock = ({slice_type, ...props}) => {
  switch(slice_type) {
    case 'image_grid':
      return <ImageGridBlock images={props.items}
        {...props.primary}
        title={props.primary.heading} />
    case 'content_block':
      return <ContentBlock {...{
        ...props.primary,
        content: props.items
      }} />
    default:
      return <div></div>
  }
}

const ServiceTemplate = ({pageContext}) => {
  const data = pageContext.data.node.data;
  const blocks = data.body;

  const caseTitles = data.body1.filter(e => (
    e.slice_type === 'case_study_title_parts'
  ));
  const caseData = {
    button: data.button_text,
    buttonColor: data.button_color,
    desktopImage: data.case_banner_image,
    titleParts: caseTitles
  }
  return (
    <main>
      <ImageBanner image={data.banner_image}
        title={data.service_title}
        titleColor={data.service_title_color}
        titleParts={data.service_title_parts} />
      {blocks.map((i,k) => (
        <SectionBlock key={k}
          {...i} />
      ))}
      <CaseStudyBanner data={caseData} />
      <ProductCategories />
      <Cta style={{backgroundColor: data.cta_background_color}}
        image={data.cta_banner_image}>
        <div className="w-full text-center">
          <p className="text-4xl md:text-5xl font-black mb-8 md:w-4/5 xl:w-1/2 mx-auto">
            {data.cta_parts.map((i,k) => (
              <span style={{color: i.color}}
                key={k}
                className={clsx({
                  'block mb-4': i.new_line
                })}>{i.text} </span>
            ))}
          </p>
          <Button label={data.cta_button_text}
            to="/contact"
            type="link"
            className={clsx('transition-colors font-black text-base md:text-lg', getButtonColor(data.cta_button_color))}
            padding="px-10 py-2" />
        </div>
      </Cta>
    </main>
  )
}

export default ServiceTemplate;

export function Head({pageContext}) {
  return (
    <Seo title={pageContext.data.node.data.meta_title}
      description={pageContext.data.node.data.meta_description}
      canonical={`/services/${pageContext.data.node.uid}`} />
  )
}