import React from "react";
import {useStaticQuery, graphql, Link} from 'gatsby';

const ProductCategories = () => {
  const prismicData = useStaticQuery(graphql`
    query {
      prismicProductCategories {
        data {
          category {
            background_color
            description {
              html
            }
            title
            title_color
            slug
          }
        }
      }
    }
  `)
  return (
    <section className="bg-white py-24">
      <div className="xl:container mx-auto px-6">
        <div className="text-center mb-12">
          <h3 className="text-4xl md:text-5xl font-black">Our Product<span className="text-sourci-teal"> Categories.</span></h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-6 gap-y-6 items-start">
          {prismicData.prismicProductCategories.data.category.map((i,k) => (
            <div className="p-4 text-white flex flex-col h-full"
              key={k}
              style={{backgroundColor: i.background_color}}>
                <div className="grow mb-6">
                  <p className="font-black text-xl mb-4"
                    style={{color: i.title_color}}>{i.title}</p>
                  <div className="text-base"
                    dangerouslySetInnerHTML={{__html: i.description.html}}></div>
                </div>
                <div className="text-right">
                  <Link to={`/categories/${i.slug}`}
                    className="font-bold">
                    Find out more {`>`}
                  </Link>
                </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ProductCategories;