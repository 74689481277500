import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import clsx from "clsx";

const ImageBanner = ({
  image,
  title,
  titleColor,
  titleParts
}) => {
  const hasContent = (title !== undefined || titleParts !== undefined);
  return (
    <section>
      <div className={clsx('relative overflow-hidden w-full h-full', {
        'min-h-[800px]': !hasContent,
        'py-24' : hasContent
      })}>
        <div className="absolute block w-full top-0 left-0 h-full">
          <GatsbyImage image={getImage(image)}
            alt={image.alt || ''}
            className="block w-full h-full" />
        </div>
        {hasContent &&
          <div className="w-full h-full">
            <div className="relative xl:container flex flex-wrap mx-auto w-full h-full items-center">
              <div className="w-full md:w-3/5 px-6">
                {title &&
                  <h1 className="text-3xl md:text-4xl font-black mb-8 md:mb-12"
                  style={{color: titleColor}}>{title}</h1>
                }
                {titleParts &&
                  <p className="text-4xl md:text-5xl font-black mb-8 md:mb-12">
                    {titleParts.map((i,k) => (
                      <span style={{color: i.color}}
                        key={k}
                        className={clsx({
                          'block mb-12': i.new_line 
                        })}>{i.part} </span>
                    ))}
                  </p>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default ImageBanner;